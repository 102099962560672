const {
  REACT_APP_FE_URL,
  REACT_APP_API_URL,
  REACT_APP_API_KEY,
  REACT_APP_LOKAL_STORAGE_PREFIX,
  REACT_APP_NAME,
  REACT_APP_NAME_SHORT,
  REACT_APP_DATA_LIMIT,
} = process.env

module.exports = {
  FE_URL: REACT_APP_FE_URL,
  BASE_API_URL: REACT_APP_API_URL,
  BASIC_API_KEY: REACT_APP_API_KEY,
  LOKAL_STORAGE_PREFIX: REACT_APP_LOKAL_STORAGE_PREFIX,
  APP_NAME: REACT_APP_NAME,
  APP_NAME_SHORT: REACT_APP_NAME_SHORT,
  DATA_LIMIT: REACT_APP_DATA_LIMIT,
}
