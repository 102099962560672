import {DATA_LIMIT} from 'configs'
import {Link} from 'react-router-dom'

const ParticipantItem = ({
  page,
  index,
  id,
  name,
  phone,
  email,
  company,
  is_survey,
  onDeleteClick,
  additionalActions,
  table,
}) => {
  const {surveyHandler, redeemHandler} = additionalActions

  return (
    <tr>
      <td className='fw-medium'>{index + page * DATA_LIMIT + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link to={`/app/${table}/edit/${id}`} className='link-warning'>
            <i className='ri-edit-2-line'></i>
          </Link>
          <Link
            to='#'
            className='link-danger'
            onClick={() => {
              onDeleteClick(id)
            }}
          >
            <i className='ri-delete-bin-5-line'></i>
          </Link>
          {/*{is_survey ? (*/}
          {/*  <button*/}
          {/*    className='btn btn-primary btn-sm'*/}
          {/*    onClick={() => redeemHandler(id)}*/}
          {/*  >*/}
          {/*    Redeem*/}
          {/*  </button>*/}
          {/*) : (*/}
          {/*  <button*/}
          {/*    className='btn btn-primary btn-sm'*/}
          {/*    onClick={() => surveyHandler(id)}*/}
          {/*  >*/}
          {/*    Survey*/}
          {/*  </button>*/}
          {/*)}*/}
        </div>
      </td>
      <td>{name}</td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>{company}</td>
    </tr>
  )
}

export default ParticipantItem
