import {
  CREATE_PARTICIPANTS_URL,
  DELETE_PARTICIPANTS_URL,
  LIST_PARTICIPANTS_URL,
  UPDATE_PARTICIPANTS_URL,
  VIEW_PARTICIPANTS_URL,
} from 'Helpers/url_helper'
import RQ from './baseRQ'

const QUERY_KEY = 'participants'
const REDIRECT_URL = '/app/participants'

export const ParticipantRQ = new RQ(
  QUERY_KEY,
  LIST_PARTICIPANTS_URL,
  VIEW_PARTICIPANTS_URL,
  CREATE_PARTICIPANTS_URL,
  UPDATE_PARTICIPANTS_URL,
  DELETE_PARTICIPANTS_URL,
  REDIRECT_URL,
)
