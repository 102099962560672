import {useQueryClient} from '@tanstack/react-query'
import ParticipantItem from 'Components/Participant/Item'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Container, Row} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import DeleteModal from '../../Components/Common/DeleteModal'
import withTable from '../../Components/Common/Table/withTable'
import {ParticipantRQ} from '../../Hooks/Queries'
import {DATA_LIMIT} from '../../configs'
const SURVEY_APP_URL = 'https://asa-event.tjalstudio.com/'

export default function Participant() {
  const [deleteParticipantId, setDeleteParticipantId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {id: salesId} = useSelector((state) => state.auth.profile)

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = ParticipantRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteParticipantId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteParticipant} =
    ParticipantRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteParticipantId(id)
  }

  const onDeleteConfirm = () => {
    deleteParticipant(deleteParticipantId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/participants/create')
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const surveyHandler = (id) => {
    window.open(
      `${SURVEY_APP_URL}sales-handler/survey/${btoa(
        JSON.stringify({participantId: id, salesId}),
      )}`,
    )
  }

  const redeemHandler = (id) => {
    window.open(
      `${SURVEY_APP_URL}sales-handler/redeem/${btoa(
        JSON.stringify({participantId: id, salesId}),
      )}`,
    )
  }

  const additionalActions = {surveyHandler, redeemHandler}

  const ParticipantTable = withTable(
    ParticipantItem,
    'participants',
    ['NO', 'Aksi', 'Nama', 'Phone', 'Email', 'Company'],
    onDeleteClick,
    onAddButtonClicked,
    additionalActions,
  )

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Partisipan' pageTitle='Partisipan' />
        <Row>
          <ParticipantTable
            data={data}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            loading={isFetching}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            error={error}
            status={status}
            fetchNextPage={fetchNextPage}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
