import React, {useEffect, useState} from 'react'

//import Components
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

import {ADMIN} from 'Helpers/options'
import {useSelector} from 'react-redux'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

const Layout = () => {
  const [headerClass, setHeaderClass] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const {pathname} = useLocation()
  const auth = useSelector((state) => state.auth)
  const {loading, token, profile} = auth

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
    // return () => window.removeEventListener('scroll', scrollNavigation, true)
  }, [])

  useEffect(() => {
    const datalayout = document.documentElement.getAttribute('data-layout')
    const dataSidebarSize =
      document.documentElement.getAttribute('data-sidebar-size')
    if (!datalayout)
      document.documentElement.setAttribute('data-layout', 'vertical')
    if (!dataSidebarSize)
      document.documentElement.setAttribute('data-sidebar-size', 'lg')
  }, [])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }

  useEffect(() => {
    setPageLoading(true)
    setTimeout(function () {
      setPageLoading(false)
    }, 500)
  }, [pathname])

  if (!loading && !token) {
    return <Navigate to='/login' state={{from: pathname}} />
  }

  return (
    <React.Fragment>
      {profile?.is_approved || profile?.role === ADMIN ? (
        <div id='layout-wrapper'>
          <Header headerClass={headerClass} />
          <Sidebar layoutType={'horizontal'} />
          <div className='main-content'>
            {pageLoading ? (
              <div id='preloader'>
                <div id='status'>
                  <div
                    className='spinner-border text-primary avatar-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <Outlet />
            )}
            <Footer />
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </React.Fragment>
  )
}

export default Layout
