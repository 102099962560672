import {createAsyncThunk} from '@reduxjs/toolkit'

export const getNotificationAction = createAsyncThunk(
  'notification/get-list',
  async (payload, _thunkApi) => {
    // const params = {page: 0, size: 10, sort: 'createdOn', direction: 'desc'}
    // if (payload?.isRead === notifStatus.read) params.is_read = true
    // if (payload?.isRead === notifStatus.unread) params.is_read = false
    // try {
    //   const response = await apiBearer.get(NOTIFICATION_LIST, {params})
    //   return {...response.data.data, ...payload}
    // } catch (error) {
    //   return _thunkApi.rejectWithValue(error.response.data)
    // }
  },
)

export const readNotificationAction = createAsyncThunk(
  'notification/read',
  async (payload, _thunkApi) => {
    // let URL = NOTIFICATION_READ_ALL
    // if (payload?.id) URL = NOTIFICATION_READ(payload?.id)
    // try {
    //   const response = await apiBearer.patch(URL)
    //   return {...response.data.data, ...payload}
    // } catch (error) {
    //   return _thunkApi.rejectWithValue(error.response.data)
    // }
  },
)

export const deleteNotificationAction = createAsyncThunk(
  'notification/delete',
  async (payload, _thunkApi) => {
    // let URL = NOTIFICATION_DELETE_ALL
    // if (payload?.id) URL = NOTIFICATION_DELETE(payload?.id)
    // try {
    //   const response = await apiBearer.delete(URL)
    //   return {...response.data.data}
    // } catch (error) {
    //   return _thunkApi.rejectWithValue(error.response.data)
    // }
  },
)
