const CREATE = '/save'
const LIST = '/lists'
const ALL = '/all'
const DETAIL = '/:id/detail'
const UPDATE = '/:id/save'
const DELETE = '/:id/delete'

const PARTICIPANTS = '/pub/participant'

export const LOGIN = '/auth/login'
export const REFRESH_TOKEN = '/auth/refresh'
export const CHANGE_PASSWORD = '/profile/change-password'
export const CREATE_PARTICIPANTS_URL = PARTICIPANTS + CREATE
export const LIST_PARTICIPANTS_URL = PARTICIPANTS + LIST
export const VIEW_PARTICIPANTS_URL = (id) =>
  PARTICIPANTS + DETAIL.replace(':id', id)
export const UPDATE_PARTICIPANTS_URL = (id) =>
  PARTICIPANTS + UPDATE.replace(':id', id)
export const DELETE_PARTICIPANTS_URL = (id) =>
  PARTICIPANTS + DELETE.replace(':id', id)
