import classnames from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'

//import images
import bell from 'assets/images/svg/bell.svg'

//SimpleBar
import {CUSTOMER, WAREHOUSE} from 'Helpers/options'
import {
  deleteNotificationAction,
  readNotificationAction,
} from 'Redux/actions/notification'
import {fetchStatuses} from 'Redux/constants'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import SimpleBar from 'simplebar-react'

const NoNotification = () => (
  <>
    <div className='w-25 w-sm-50 pt-3 mx-auto'>
      <img src={bell} className='img-fluid' alt='user-pic' />
    </div>
    <div className='text-center pb-5 mt-2'>
      <h6 className='fs-18 fw-semibold lh-base'>
        Belum ada notifikasi yang dapat ditampilkan{' '}
      </h6>
    </div>
  </>
)

const getLink = (type, role) => {
  switch (type) {
    case 'BOOKING':
      if (role === WAREHOUSE) return '/app/shipping-management/detail/'
      if (role === CUSTOMER) return '/app/truck-book/booking/detail/'
      break
    case 'CDO':
      if (role === WAREHOUSE) return '/app/request-cdo/detail/'
      if (role === CUSTOMER) return '/app/cdo/detail/'
      break
    default:
      return '/app'
  }
}

const NotificationItem = ({data, closeNotif}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const status = useSelector((state) =>  state.notification.status)
  const role = useSelector((state) => state.auth.profile?.role)
  const redirectUrl = getLink(data.type, role)

  const {is_read} = data

  const onClick = () => {
    if (!is_read) dispatch(readNotificationAction({id: data.id}))
    closeNotif()
    navigate(redirectUrl + data.reff_id)
  }
  return (
    <div
      className='text-reset notification-item d-block dropdown-item position-relative'
      key={data.id}
      role='button'
      onClick={onClick}
    >
      <div className='d-flex align-items-center'>
        <div className='avatar-xs me-3'>
          <span
            className={
              'avatar-title rounded-circle fs-16 ' +
              (is_read
                ? 'bg-soft-dark text-dark'
                : 'bg-soft-success text-success')
            }
          >
            <i className='bx bx-bell'></i>
          </span>
        </div>
        <div className='flex-1 '>
          <h5
            className={
              'mt-0 mb-2 lh-base ' + (is_read ? 'text-dark' : 'text-success')
            }
          >
            {data.title}
          </h5>
          <h6
            className={
              'lh-base mb-1 mt-0 ' + (is_read ? 'text-dark' : 'text-success')
            }
          >
            {data.message}
          </h6>
          <p
            className={
              'mb-0 fs-11 fw-medium text-uppercase ' +
              (is_read ? 'text-dark' : 'text-success')
            }
          >
            <span>
              <i className='mdi mdi-clock-outline'></i>{' '}
              {moment(data.date).format('DD MMMM YYYY, HH:mm')}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const NotificationDropdown = () => {
  const dispatch = useDispatch()
  const {all, read, unread, status} = useSelector((state) => state.notification)

  const isNotifExists = all.length || read.length || unread.length

  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)
  const [notifSettingDropdown, setNotifSettingDropdown] = useState(false)

  const toggleNotificationDropdown = useCallback(() => {
    setIsNotificationDropdown((prev) => !prev)
  }, [])

  const toogleSettingNotif = useCallback(() => {
    setNotifSettingDropdown((prev) => !prev)
  }, [])

  //Tab
  const [activeTab, setActiveTab] = useState('1')
  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab)
      }
    },
    [activeTab],
  )

  useEffect(() => {
    // dispatch(getNotificationAction())
    // dispatch(getNotificationAction({isRead: notifStatus.unread}))
    // dispatch(getNotificationAction({isRead: notifStatus.read}))
  }, [dispatch])

  useEffect(() => {
    // if (status === fetchStatuses.succeeded || status === fetchStatuses.failed)
    // dispatch(setNotifStatusIddle())
  }, [dispatch, status])

  useEffect(() => {
    if (!isNotificationDropdown) setNotifSettingDropdown(false)
  }, [isNotificationDropdown])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className='topbar-head-dropdown ms-1 header-item'
      >
        <DropdownToggle
          type='button'
          tag='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        >
          <i className='bx bx-bell fs-22'></i>
          {isNotifExists ? (
            <span
              className='position-absolute topbar-badge fs-10 translate-middle rounded-circle bg-danger'
              style={{height: 10, width: 10, bottom: 0, right: 5}}
            >
              <span className='visually-hidden'>unread messages</span>
            </span>
          ) : null}
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-xl dropdown-menu-end p-0'>
          <div className='dropdown-head bg-primary bg-pattern rounded-top'>
            <div className='p-3'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0 fs-16 fw-semibold text-white'>
                    {' '}
                    Notifications{' '}
                  </h6>
                </Col>
                <div className='col-auto dropdown-tabs'>
                  <Dropdown
                    isOpen={notifSettingDropdown && isNotificationDropdown}
                    toggle={toogleSettingNotif}
                    disabled={status === fetchStatuses.pending}
                  >
                    <DropdownToggle tag='div'>
                      <i
                        className={
                          'fs-22 text-white mdi mdi-cog ' +
                          (status === fetchStatuses.pending ? 'mdi-spin' : '')
                        }
                        role='button'
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        toggle
                        className='d-flex align-item-center'
                        onClick={() => dispatch(readNotificationAction())}
                      >
                        <i className='bx bx-list-check fs-22'></i>{' '}
                        <span className='ms-2'>Tandai Telah Baca</span>
                      </DropdownItem>
                      <DropdownItem
                        toggle
                        className='d-flex align-item-center'
                        onClick={() => dispatch(deleteNotificationAction())}
                      >
                        <i className='bx bx-trash fs-22'></i>{' '}
                        <span className='ms-2'>Hapus Notifikasi</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Row>
            </div>
            <div className='px-2 pt-2'>
              <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({active: activeTab === '1'})}
                    onClick={() => {
                      toggleTab('1')
                    }}
                  >
                    Semua
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({active: activeTab === '2'})}
                    onClick={() => {
                      toggleTab('2')
                    }}
                  >
                    Belum Dibaca
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({active: activeTab === '3'})}
                    onClick={() => {
                      toggleTab('3')
                    }}
                  >
                    Sudah Dibaca
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId='1' className='py-2 ps-2'>
              <SimpleBar style={{maxHeight: '300px'}} className='pe-2'>
                {all.length > 0 ? (
                  all.map((data) => (
                    <NotificationItem
                      key={data.id}
                      data={data}
                      closeNotif={toggleNotificationDropdown}
                    />
                  ))
                ) : (
                  <NoNotification />
                )}
                {/* <div className="my-3 text-center">
                                    <button type="button" className="btn btn-soft-success waves-effect waves-light">View
                                        All Notifications <i className="ri-arrow-right-line align-middle"></i></button>
                                </div> */}
              </SimpleBar>
            </TabPane>

            <TabPane tabId='2' className='py-2 ps-2'>
              <SimpleBar style={{maxHeight: '300px'}} className='pe-2'>
                {unread.length > 0 ? (
                  unread.map((data) => (
                    <NotificationItem
                      key={data.id}
                      data={data}
                      closeNotif={toggleNotificationDropdown}
                    />
                  ))
                ) : (
                  <NoNotification />
                )}
              </SimpleBar>
            </TabPane>
            <TabPane tabId='3' className='py-2 ps-2'>
              <SimpleBar style={{maxHeight: '300px'}} className='pe-2'>
                {read.length > 0 ? (
                  read.map((data) => (
                    <NotificationItem
                      key={data.id}
                      data={data}
                      closeNotif={toggleNotificationDropdown}
                    />
                  ))
                ) : (
                  <NoNotification />
                )}
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown
