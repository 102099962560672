import {yupResolver} from '@hookform/resolvers/yup'
import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {apiBasic} from 'Helpers/api'
import {validPhone} from 'Helpers/regex_validation'
import {ParticipantRQ} from 'Hooks/Queries'
import useDebounce from 'Hooks/useDebounce'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

// const specializationOptions = [
//   {label: 'DSA', value: 'DSA'},
//   {label: 'PPDS', value: 'PPDS'},
//   {label: 'PG', value: 'PG'},
//   {label: 'LAINNYA', value: 'LAINNYA'},
// ]

export default function EditCreateParticipant(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const profile = useSelector((state) => state.auth.profile)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [search, setSearch] = useState('')
  const [items, setItems] = useState([])
  const debounsceSearch = useDebounce(search, 500)

  useQuery(['candidates-search', debounsceSearch], () =>
    apiBasic
      .get(`pub/candidate/lists?page=0&search=${debounsceSearch}&size=10`)
      .then((response) => {
        setItems(response?.data?.data?.data ?? [])
        return response?.data?.data
      })
      .catch((error) => {
        setItems([])
        return error
      }),
  )

  const formatResult = (item) => {
    return (
      <div className='d-flex'>
        <span style={{display: 'block', minWidth: 100}}>{item.name}</span>
        <span style={{display: 'block'}} className='mx-3'>
          -
        </span>
        <span style={{display: 'block', minWidth: 100}}>{item.phone}</span>
      </div>
    )
  }

  const validation = Yup.object().shape({
    name: Yup.string().required('Nama tidak boleh kosong'),
    phone: Yup.string()
      .required('Nomor hp tidak boleh kosong')
      .test('test-valid-name', 'Nomor hp tidak valid', (v) =>
        validPhone.test(v),
      ),
    sales_promotion_id: Yup.string().required('Sales tidak boleh kosong'),
    company: Yup.string().required('Perusahaan tidak boleh kosong'),
    email: Yup.string().required('Email tidak boleh kosong'),
    answer1: Yup.string(),
    answer2: Yup.string(),
    answer3: Yup.string()
  })

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      phone: '',
      company: '',
      email: '',
      answer1: '',
      answer2: '',
      answer3: '',
      sales_promotion_id: profile?.id
    },
  }
  const {handleSubmit, formState, control, reset, register, watch} =
    useForm(formOptions)
  const {errors} = formState

  const {mutate: updateParticipant, isLoading: updateLoading} =
    ParticipantRQ.useUpdateData(navigate)()

  const _updateParticipant = (values) => {
    const data = values
    data.id = id
    const {sales_promotion_id: _, ...dataWithout} = values
    updateParticipant(dataWithout)
  }

  const {mutate: addParticipant, isLoading: createLoading} =
    ParticipantRQ.useAddData(navigate)()

  const _createParticipant = (values) => {
    addParticipant(values)
  }

  const {data, error, isFetching, status} = ParticipantRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        name: data?.data?.name ?? '',
        phone: data?.data?.phone ?? '',
        company: data?.data?.company ?? '',
        email: data?.data?.email ?? '',
        answer1: data?.data?.answer1 ?? '',
        answer2: data?.data?.answer2 ?? '',
        answer3: data?.data?.answer3 ?? '',
        sales_promotion_id: data?.data?.sales_promotion?.id ?? '-',
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  const isLoading = createLoading || updateLoading
  const submitBtnText = () => {
    if (isLoading) return 'Loading...'
    return id ? 'Update' : 'Create'
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Participant' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateParticipant : _createParticipant,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Participant
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label htmlFor='name'>
                          Full Name <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('name')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.phone}
                        message={errors.phone?.message}
                      >
                        <Label htmlFor='phone'>
                          No. Whatsapp/Phone <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('phone')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.email}
                        message={errors.email?.message}
                      >
                        <Label htmlFor='email'>
                          Email Address <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('email')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper
                        error={errors.company}
                        message={errors.company?.message}
                      >
                        <Label
                          htmlFor='company'
                          className='form-label text-uppercase'
                        >
                          Company <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('company')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={12}>
                      <ErrorFieldWrapper
                        error={errors.answer1}
                        message={errors.answer1?.message}
                      >
                        <Label
                          htmlFor='answer1'
                          className='form-label text-uppercase'
                        >
                          1. Apakah Anda menggunakan teknologi AI dalam pekerjaan Anda? Jika ya, bagaimana Anda menggunakannya?
                          <span className='mandatory'>*</span>
                        </Label>
                        <textarea
                          className='form-control'
                          {...register('answer1')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={12}>
                      <ErrorFieldWrapper
                        error={errors.answer2}
                        message={errors.answer2?.message}
                      >
                        <Label
                          htmlFor='answer2'
                          className='form-label text-uppercase'
                        >
                          2. Jenis data apa yang sedang Anda kelola? <span className='mandatory'>*</span>
                        </Label>
                        <textarea
                          className='form-control'
                          {...register('answer2')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={12}>
                      <ErrorFieldWrapper
                        error={errors.answer3}
                        message={errors.answer3?.message}
                      >
                        <Label
                          htmlFor='answer3'
                          className='form-label text-uppercase'
                        >
                          3. Apa harapan Anda dari penggunaan AI dalam membantu pekerjaan Anda? <span className='mandatory'>*</span>
                        </Label>
                        <textarea
                          className='form-control'
                          {...register('answer3')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/participants' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
