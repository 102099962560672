import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import {logoutAction} from '../../Redux/actions/auth'

const ProfileDropdown = () => {
  const dispatch = useDispatch()

  const {user} = useSelector((state) => ({
    user: state.auth.profile,
  }))

  const [userName, setUserName] = useState('Admin')
  const [userRole, setUserRole] = useState('Admin')

  useEffect(() => {
    if (user) {
      setUserName(user.email?.split('@')[0] || 'Admin')
      setUserRole(user.role)
    }
  }, [userName, user])

  const [qrModal, setQrModal] = useState(false)
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => setIsProfileDropdown((v) => !v)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'
      >
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            <span className='rounded-circle header-profile-user bg-primary text-white d-flex justify-content-center align-items-center'>
              {user.avatar ? (
                <img
                  className='rounded-circle header-profile-user'
                  src={user.avatar}
                  alt='Header Avatar'
                  style={{objectFit: 'cover', objectPosition: 'center'}}
                />
              ) : (
                userName.charAt(0).toUpperCase()
              )}
            </span>
            <span className='text-start ms-xl-2'>
              <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {userName}
              </span>
              <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                {userRole}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <h6 className='dropdown-header'>Welcome {userName}!</h6>
          {/* <DropdownItem>
            <Link to='/app/profile'>
              <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
              <span className='align-middle'>Profile</span>
            </Link>
          </DropdownItem> */}
          <DropdownItem onClick={() => setQrModal(true)}>
            <img src={user?.qr_code} alt='' width={100} />
          </DropdownItem>

          <DropdownItem onClick={() => dispatch(logoutAction())}>
            <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
            <span className='align-middle' data-key='t-logout'>
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal
        fullscreen
        isOpen={qrModal}
        toggle={() => setQrModal((prev) => !prev)}
        centered={true}
      >
        <ModalBody className='d-flex justify-content-center'>
          <img src={user?.qr_code} alt='' className='h-100' />
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <div
            onClick={() => setQrModal(false)}
            className='d-flex justify-content-between align-items-center btn btn-primary'
          >
            <i className='bx bx-exit-fullscreen fs-22' />{' '}
            <span className='waves-effect waves-light fw-bold'>Tutup</span>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ProfileDropdown
