import {createAsyncThunk} from '@reduxjs/toolkit'
import {apiBasic} from 'Helpers/api'
import {LOGIN} from 'Helpers/url_helper'
import {DRIVER} from '../../Helpers/options'

export const loginAction = createAsyncThunk(
  'auth/login',
  async (payload, _thunkApi) => {
    try {
      const loginPayload = {...payload}
      delete loginPayload.redirectTo
      delete loginPayload.loginType
      const response = await apiBasic.post(LOGIN, {...loginPayload})
      if (response.data.data.role == DRIVER) {
        return _thunkApi.rejectWithValue({
          message: 'Please log in from your mobile app',
        })
      }
      return {...response.data.data, redirectTo: payload.redirectTo}
    } catch (error) {
      return _thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const refreshTokenAction = createAsyncThunk(
  'auth/refresh-token',
  async (payload, _thunkApi) => {
    // try {
    // const refreshPayload = {...payload}
    // let URL = REFRESH_TOKEN
    // if (payload.refreshType === REFRESH_TOKEN_NLE) URL = REFRESH_TOKEN_NLE
    // delete refreshPayload.refreshType
    // const response = await apiBearer.post(URL, {...refreshPayload})
    //   return {...response.data.data, refreshType: payload.refreshType}
    // } catch (error) {
    //   return _thunkApi.rejectWithValue(error.response.data)
    // }
  },
)

export const logoutAction = createAsyncThunk(
  'auth/logout',
  async (_, {fulfillWithValue}) => {
    return fulfillWithValue({message: 'success'})
  },
)
