import {useLocalStorage} from 'Hooks'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const Navdata = () => {
  const navigate = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)

  const [isHakAskses, setIsHakAkses] = useState(false)

  const [iscurrentState, setIscurrentState] = useLocalStorage(
    'currentPage',
    null,
  )

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        const id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'hak-akses') {
      setIsHakAkses(false)
    }
  }, [navigate, iscurrentState])

  const menuItemsAdmin = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/app/Dashboard',
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('dashboard')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      icon: 'ri-user-3-fill',
      id: 'participants',
      label: 'Participants',
      link: '/app/participants',
      click: function (e) {
        e.preventDefault()
        setIsHakAkses(!isHakAskses)
        setIscurrentState('hak-akses')
        updateIconSidebar(e)
      },
      stateVariables: isHakAskses,
    },
  ]
  const renderMenuItems = () => menuItemsAdmin

  return <React.Fragment>{renderMenuItems()}</React.Fragment>
}
export default Navdata
